import { CartSteeringWheelIconProps } from './car-steering-wheel-icon.props';

const CartSteeringWheelIcon = ({ className, style }: CartSteeringWheelIconProps) => {
    return (
        <div className={className} style={style}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
                <g stroke="#ACB9C8" strokeWidth="2" clipPath="url(#clip0_41073_100887)">
                    <ellipse cx="10" cy="9.863" rx="8.333" ry="8.197"></ellipse>
                    <path d="M1.76 8.133l5.799-.64c1.622-.18 3.26-.18 4.882 0l5.8.64M1.76 9.772l.882.231a8.61 8.61 0 016.432 8.33v0M18.24 9.772l-.882.231a8.61 8.61 0 00-6.432 8.33v0"></path>
                    <path strokeLinecap="round" d="M18.333 1.667L1.667 18.06"></path>
                </g>
                <defs>
                    <clipPath id="clip0_41073_100887">
                        <path fill="#fff" d="M0 0H20V20H0z"></path>
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};

export default CartSteeringWheelIcon;
