import { graphql, useStaticQuery } from 'gatsby';
import { BIRDI_OPEN_HOURS, US_HOLIDAYS } from 'gatsby-env-variables';
import { useMemo } from 'react';

import { isUsHoliday, timestampToHours } from 'util/date';
import { stringToArray } from 'util/string';

const WeekDaysQuery = () => {
    return useStaticQuery<GatsbyTypes.OpenWeekDaysQuery>(graphql`
        query OpenWeekDays {
            allSiteSettingEntityEnvironmentSettings {
                nodes {
                    relationships {
                        field_birdi_open_weekdays {
                            name
                            field_day_value
                        }
                    }
                }
            }
        }
    `);
};

/**
 * Custom hook to determine if the current time is within the open hours of a business
 * based on Central Time (CT). The hook checks if the current day is a weekday,
 * whether the current time falls within open hours, and whether the day is a holiday.
 *
 * @returns {{ isOpen: boolean, holidays: string[], openHours: string }} Returns an object containing:
 *    - isOpen: true if the business is open; otherwise, false.
 *    - holidays: Array of formatted holiday dates (MM/DD).
 *    - openHours: A string representing the formatted open hours (e.g., '08:00AM - 08:00PM').
 */
export function useOpenHours(): { isOpen: boolean; holidays: string[]; openHours: string } {
    const data: GatsbyTypes.OpenWeekDaysQuery = WeekDaysQuery();

    const holidays = stringToArray(US_HOLIDAYS);
    const { from, to } = BIRDI_OPEN_HOURS;
    const weekDays =
        data.allSiteSettingEntityEnvironmentSettings?.nodes[0].relationships?.field_birdi_open_weekdays || [];

    const openHours = `${timestampToHours(from)} - ${timestampToHours(to)}`;

    const isOpen = useMemo(() => {
        const userDate = new Date();

        // Define options for formatting the date and time in Central Time (CST)
        const options: Intl.DateTimeFormatOptions = {
            timeZone: 'America/Chicago',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false // Use 24-hour format for consistency with system requirements
        };

        // Format the user's date and time to Central Time (CST) based on America/Chicago timezone
        const centralTimeString = new Intl.DateTimeFormat('en-US', options).format(userDate);
        const [datePart, timePart] = centralTimeString.split(', ');
        const [month, day, year] = datePart.split('/').map(Number);
        const [hours, minutes, seconds] = timePart.split(':').map(Number);

        const currentTimeInSeconds = hours * 3600 + minutes * 60 + seconds;

        // Create a UTC Date object based on Central Time components to avoid local time zone interference
        const centralTime = new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds));

        const dayOfWeek = centralTime.getUTCDay();

        const isOpenDay = weekDays.some((day) => day?.field_day_value === dayOfWeek);
        const isInOpenHours = currentTimeInSeconds >= from && currentTimeInSeconds < to;
        const isTodayHoliday = isUsHoliday(centralTime, holidays);

        return isOpenDay && isInOpenHours && !isTodayHoliday;
    }, [holidays, from, to]);

    return { isOpen, holidays, openHours };
}
