import { LetterIconProps } from './letter-icon.props';

const LetterIcon = ({ className, style }: LetterIconProps) => {
    return (
        <div className={className} style={style}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
                <path
                    stroke="#ACB9C8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1.667 5.655L9.179 9.85c.23.126.521.195.821.195.3 0 .59-.07.82-.195l7.513-4.195m-1.282-1.488H2.95c-.708 0-1.282.533-1.282 1.19v10.12c0 .657.574 1.19 1.282 1.19H17.05c.708 0 1.282-.533 1.282-1.19V5.356c0-.657-.574-1.19-1.282-1.19z"
                ></path>
            </svg>
        </div>
    );
};

export default LetterIcon;
