import { ParentIconProps } from './parent-icon.props';

const ParentIcon = ({ className, style }: ParentIconProps) => {
    return (
        <div className={className} style={style}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
                <g clipPath="url(#clip0_38548_17822)">
                    <path
                        stroke="#ACB9C8"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M11.55 14.915a4.543 4.543 0 016.338-.253m-5.115 4.055c-.14-1.68-.559-2.786-.788-3.175a5.568 5.568 0 00-1.995-1.994 5.33 5.33 0 00-2.683-.727c-.94 0-1.863.25-2.682.727a5.54 5.54 0 00-1.676 1.51l-.287.429m12.186-4.077a2.27 2.27 0 110-4.541 2.27 2.27 0 010 4.541zm-7.346-.728a2.814 2.814 0 110-5.628 2.814 2.814 0 010 5.628zM10 19.167A9.167 9.167 0 1110 .833a9.167 9.167 0 010 18.334z"
                    ></path>
                </g>
                <defs>
                    <clipPath id="clip0_38548_17822">
                        <path fill="#fff" d="M0 0H20V20H0z"></path>
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};

export default ParentIcon;
