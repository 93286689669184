import { DoctorIconProps } from './doctor-icon.props';

const DoctorIcon = ({ className, style }: DoctorIconProps) => {
    return (
        <div className={className} style={style}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                <path
                    d="M10.9581 9.55882C12.8732 9.55882 14.4257 7.97866 14.4257 6.02941C14.4257 4.08017 12.8732 2.5 10.9581 2.5C9.04293 2.5 7.49042 4.08017 7.49042 6.02941C7.49042 7.97866 9.04293 9.55882 10.9581 9.55882Z"
                    stroke="#00856E"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M2 21.8383C2 19.5566 2.88797 17.3685 4.46856 15.7551C6.04916 14.1417 8.1929 13.2354 10.4282 13.2354C11.0989 13.2354 11.7614 13.317 12.4029 13.4748V21.8383"
                    stroke="#00856E"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.35756 16.6177V19.5589M6.9126 18.0882L9.8023 18.0882"
                    stroke="#00856E"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M21.3581 13.0883H16.2234C15.8689 13.0883 15.5815 13.4124 15.5815 13.8123V21.776C15.5815 22.1759 15.8689 22.5 16.2234 22.5H21.3581C21.7126 22.5 22 22.1759 22 21.776V13.8123C22 13.4124 21.7126 13.0883 21.3581 13.0883Z"
                    stroke="#00856E"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M18.1582 19.6639H19.1209"
                    stroke="#00856E"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};

export default DoctorIcon;
