import { differenceInYears, isValid, parse } from 'date-fns';
import { enUS } from 'date-fns/locale';
import moment from 'moment';

export const calculateAge = (dob: string): number => {
    return differenceInYears(new Date(), new Date(dob));
};

// Gets secure date considering any modification on
// the browser caused by timezone conversion
export const parseSecureDate = (date: string) => {
    var userTimezoneOffset = moment().utcOffset();
    return moment.parseZone(date).utcOffset(userTimezoneOffset, true).format('MM-DD-yyyy');
};

export function isValidDate(date: string) {
    const parsedDate = parse(date, 'P', new Date(), { locale: enUS });
    const isValidDate = isValid(parsedDate);

    return isValidDate;
}

/**
 * Determines whether a given date is a holiday based on an array of US holidays.
 *
 * @param {Date} currentDate - The current date to check.
 * @param {string[]} usHolidays - An array of US holidays in 'MM/DD' format.
 * @returns {boolean} Returns true if the current date is a holiday, false otherwise.
 */
export function isUsHoliday(currentDate: Date, usHolidays: string[]): boolean {
    const monthDay = currentDate.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' });
    return usHolidays.includes(monthDay);
}

/**
 * Checks if a given day of the week is a weekday (Monday to Friday).
 *
 * @param {number} weekDay - The day of the week (0 for Sunday, 1 for Monday, etc.).
 * @returns {boolean} Returns true if the day is a weekday (Monday to Friday), false otherwise.
 */
export function isWeekday(weekDay: number): boolean {
    return weekDay >= 1 && weekDay <= 5;
}

/**
 * Converts a timestamp in seconds to a formatted time string (e.g., '08:00AM').
 *
 * @param {number} seconds - The timestamp in seconds.
 * @returns {string} - Returns the formatted time string in the format 'HH:MMAM/PM'.
 */
export const timestampToHours = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const suffix = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}${suffix}`;
};
