import { TicketIconProps } from './ticket-icon.props';

const TicketIcon = ({ className, style }: TicketIconProps) => {
    return (
        <div className={className} style={style}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
                <g>
                    <path
                        stroke="#ACB9C8"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12.885 3.344v2.264m0 3.052v2.264m0 3.062v2.264M1.667 14.87c0 .762.612 1.38 1.367 1.38h13.932c.755 0 1.367-.618 1.367-1.38v-2.539a2.628 2.628 0 01-1.923-2.54c0-1.213.815-2.236 1.923-2.539V4.713c0-.762-.612-1.38-1.367-1.38H3.034c-.755 0-1.367.618-1.367 1.38v2.534A2.628 2.628 0 013.61 9.792c0 1.222-.825 2.249-1.944 2.545v2.533z"
                    ></path>
                </g>
            </svg>
        </div>
    );
};

export default TicketIcon;
