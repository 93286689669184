import { CSSProperties } from 'react';

export interface CircleInfoProps {
    style?: CSSProperties;
    className?: string;
}

const CircleInfoV2 = ({ className, style }: CircleInfoProps) => {
    return (
        <div className={className} style={style}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <g clipPath="url(#clip0_41635_2296)">
                    <path
                        stroke="#1A497F"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11z"
                    ></path>
                    <path
                        fill="#1A497F"
                        d="M10.992 17.2v-6.048c0-.64.16-.8.8-.8h.4c.64 0 .8.16.8.8V17.2c0 .64-.16.8-.8.8h-.4c-.64 0-.8-.16-.8-.8zm-.144-9.424c0-.624.384-1.152 1.152-1.152.768 0 1.136.528 1.136 1.152 0 .624-.368 1.136-1.136 1.136-.768 0-1.152-.512-1.152-1.136z"
                    ></path>
                </g>
                <defs>
                    <clipPath id="clip0_41635_2296">
                        <path fill="#fff" d="M0 0H24V24H0z"></path>
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};

export default CircleInfoV2;
