import { VideoIconProps } from './video-icon.props';

const VideoIcon = ({ className, style }: VideoIconProps) => {
    return (
        <div className={className} style={style}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
                <g clipPath="url(#clip0_38548_17827)">
                    <path
                        stroke="#ACB9C8"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13.69 12.309L7.793 6.41m2.948 7.116a4.167 4.167 0 100-8.334 4.167 4.167 0 000 8.334zM6.795 17.05l-5.128 1.282 1.282-3.846V2.95A1.282 1.282 0 014.23 1.667h12.82a1.282 1.282 0 011.282 1.282v12.82a1.282 1.282 0 01-1.282 1.282H6.795z"
                    ></path>
                </g>
                <defs>
                    <clipPath id="clip0_38548_17827">
                        <path fill="#fff" d="M0 0H20V20H0z"></path>
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};

export default VideoIcon;
