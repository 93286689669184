import React, { useEffect, useRef } from 'react';

import './../ui-kit/utils/skeleton/skeleton.styles.scss';

export interface WithSkeletonProps {
    isLoading?: boolean;
    skeletonHeight?: number;
}

const withSkeleton = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
    return (props: P & WithSkeletonProps) => {
        const { isLoading, skeletonHeight = 200, ...rest } = props;
        const wrappedRef = useRef<HTMLDivElement>(null);
        const [wrappedHeight, setWrappedHeight] = React.useState<number | undefined>(undefined);

        useEffect(() => {
            if (!isLoading && wrappedRef.current) {
                const height = wrappedRef.current.clientHeight;
                setWrappedHeight(height);
            }
        }, [isLoading]);

        const skeletonStyleHeight = skeletonHeight ?? wrappedHeight;

        return (
            <>
                {isLoading ? (
                    <div className="skeleton" style={{ height: skeletonStyleHeight }} />
                ) : (
                    <div ref={wrappedRef}>
                        <WrappedComponent {...(rest as P)} />
                    </div>
                )}
            </>
        );
    };
};

export default withSkeleton;
